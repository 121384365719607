import Vue from 'vue'
import VueRouter from 'vue-router'
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
import VueCookies from "vue-cookies"; // 设置cookies
const NotFount = () => import('@/views/404.vue');
const Index = () => import('@/views/index.vue');
const Login = () => import('@/views/login.vue');
const Reg = () => import('@/views/reg.vue');
// 懒加载 https://www.cnblogs.com/cchHers/p/10010332.htmlin
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    redirect: '/home',
    component: Index,
    children: [
      {
        path: '/home',
        name: 'home',
        redirect: '/home/solution',
        component: () => import('@/views/index/home/index.vue'),
        children: [
          {
            path: 'solution',
            name: 'solution',
            component: () => import('@/views/index/home/solution.vue'),
          },
          {
            path: 'netred',
            name: 'netred',
            component: () => import('@/views/index/home/netred.vue'),
          },
          {
            path: 'commerce',
            name: 'commerce',
            component: () => import('@/views/index/home/commerce.vue'),
          },
          {
            path: 'brand',
            name: 'brand',
            component: () => import('@/views/index/home/brand.vue'),
          },
          {
            path: 'added',
            name: 'added',
            component: () => import('@/views/index/home/added.vue'),
          },
        ]
      }, {
        path: '/cooperation',
        name: 'cooperation',
        component: () => import('@/views/index/cooperation/index.vue'),
      },
      {
        path: '/aboutus',
        name: 'aboutus',
        component: () => import('@/views/index/aboutus/index.vue')
      },
      {
        path: '/college',
        name: 'college',
        component: () => import('@/views/index/college/index.vue')
      },
      {
        path: '/case',
        name: 'case',
        component: () => import('@/views/index/case/index.vue')
      },
      {
        path: '/qianhe',
        name: 'qianhe',
        component: () => import('@/views/index/qianhe/index.vue')
      },
    ]
  },
  {
    path: "/login",
    name: "login",
    component: Login
  },
  {
    path: "/reg",
    name: "reg",
    component: Reg
  },
  {
    path: "*",
    name: "404",
    component: NotFount
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {

  if (to.path === '/login') return next();

  // if (!VueCookies.get('tokenid') && to.path === '/college') return next('/login');

  next();
});

export default router

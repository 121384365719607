import axios from "axios"; // 请求
import NProgress from 'nprogress'; // 顶部进度条
import VueCookies from 'vue-cookies'; // 设置cookies
import toast from '../toast.js'; // 基于element-ui的二次封装全局提示信息
const timeout = 5000; // 超时 单位ms
axios.defaults.timeout = timeout;
axios.interceptors.request.use(
    // 配置
    config => {
        return config;
    },
    // 失败
    error => {
        return error
    }
);
axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        error = JSON.parse(JSON.stringify(error));
        let msg = error.message;
        if (msg == `timeout of ${timeout}ms exceeded`) return {
            code: -1,
            msg: '请求超时',
            response: true
        }
        if (msg == `Request failed with status code 404`) return {
            code: 404,
            msg: '找不到资源404',
            response: true
        }
        return {
            msg,
            code: -1,
            response: true
        }
    }
);
// 当登录过期统一处理
const gohrefifs = () => {
    VueCookies.remove('tokenid');
    window.location.href = window.location.href.split('/#/')[0]
}
// 暴露外部接口
export function ajaxpost(url, params = {}, type) {
    let token = VueCookies.get('tokenid');
    let islogin = url.indexOf('/user/login') == -1;
    let isreg = url.indexOf('/user/reg') == -1;
    if (islogin && isreg && (!token || token == '')) return new Promise((resolve, reject) => {
        return reject(gohrefifs())
    })
    NProgress.start(); // 开启进度条
    params['requesttype'] = 'source_admin';
    return new Promise((resolve, reject) => {
        axios[type](url, type == 'get' ? {
            params
        } : params).then(
            response => {
                // console.log(response)
                NProgress.done(); // 关闭进度条
                if (response && response.response) {
                    toast(response.msg);
                    return resolve({
                        code: response.code,
                        msg: ''
                    });
                }
                let data = response.data;
                // 当前用户登陆失效重新登陆 699 当前用户token过期 697 当前用户已被封停
                if (data.code === 699 || data.code === 697) {
                    toast(data.msg);
                    return gohrefifs();
                }
                // 当前用户权限不足
                if (data.code === 698) return toast(data.msg);
                // 请求来源不存在
                if (data.code === 696) return toast(data.msg);
                return resolve(data);
            }
        ).catch(error => {
            NProgress.done(); // 关闭进度条
            reject(error);
        });
    });
}
import { ajaxpost } from "./req.js"; // 引入封装的请求
let Interface =  process.env.NODE_ENV == "development" ? '/el' : '/api';
export default { 
    // 登录
    login(param) {
        return ajaxpost(Interface + '/api/user/login', param, 'post');
    },
    // 注册
    reg(param) {
        return ajaxpost(Interface + '/user/reg', param, 'post');
    },
}
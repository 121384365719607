/**
 * @param {*当前全局this指向} _this 
 * @param {*提示内容} text 
 * @param {*成功还是失败} type 
 * @param {*显示时间} time 
 */
import {
    Notification
} from 'element-ui'
export default function(text, type, time) {
    if (text && text != '') {
        Notification({
            title: '系统提示',
            message: text || '操作失败 defa',
            dangerouslyUseHTMLString: true,
            duration: time || 3000,
            background: type ? "#1989fa" : "#f44",
            color: 'white',
            type: type ? 'success' : 'error'
        });
    }
}
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// 重置样式
import '@/assets/style/reset.css';
// 重置element-ui样式
import '@/assets/style/reset-element-ui.css';
import ajax from '@/assets/js/ajax/info.js';
Vue.prototype.$ajax = ajax;
import toast from '@/assets/js/toast.js';
Vue.prototype.toast = toast;

Vue.config.productionTip = false
Vue.use(ElementUI);
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

